<template>

  <b-card title="Estibas del pedido">

    
    <b-row>

      <b-col cols="12" class="mb-2" v-if="order">

        <small>Detalle del pedido</small>
        <h6>Tipo de producto: {{ order.product_type.name }}</h6>
        <h6>Cliente: {{ order.client.business_name }}</h6>
        <h6>Destino: {{ order.destination.name + ' - ' + order.destination.address }}</h6>
        <h6>Cantidad de seriales: {{ order.quantity_products }}</h6>
        
      </b-col>

      <b-col 
        v-for="(stowage, index) in stowages"
        :key="index"
        cols="12">

        <b-card
          no-body
          class="card-apply-job"
          bg-variant="light-secondary"
        >
          <b-card-header class="pb-1">
            <b-media no-body>
              <b-media-body>
                <h5 class="mb-0">
                  Estiba #{{ stowage.stowage.stowage_number }} | {{ stowage.stowage.boxes_count }} cajas
                </h5>
                <small class="text-muted">Creada: {{ formatDateVariant(stowage.stowage.created_at) }}</small>
              </b-media-body>
            </b-media>
          
          </b-card-header>
          <b-card-body>

            <b-row>
              <b-col 
                v-for="(box, index) in stowage.stowage.boxes"
                :key="index"
                cols="3">

                <b-card
                  class="card-apply-job"
                  :title="'Caja #' + box.box_number"
                >

                  <h5 class="text-primary">{{ box.products_by_box }} seriales</h5>

                  <b-button
                    block
                    variant="primary"
                    class="mt-2"
                    @click="$router.push({name: 'products-by-box', params: { box_id:box.id } })"
                  >
                    Ver seriales
                  </b-button>

                </b-card>

              </b-col>
            </b-row>

          </b-card-body>
        </b-card>

      </b-col>

      <!-- <b-col 
        v-for="(stowage, index) in stowages"
        :key="index"
        cols="3">

        <b-card
          no-body
          class="card-apply-job"
          bg-variant="light-secondary"
        >
          <b-card-header class="pb-1">
            <b-media no-body>
              <b-media-body>
                <h5 class="mb-0">
                  Estiba #{{ stowage.stowage.stowage_number }}
                </h5>
                <small class="text-muted">Creada: {{ formatDateVariant(stowage.stowage.created_at) }}</small>
              </b-media-body>
            </b-media>
            <b-badge
              pill
              variant="light-success"
            >
              {{ stowage.stowage.available == 1 ? 'Disponible' : 'No disponible' }}
            </b-badge>
          </b-card-header>
          <b-card-body>

            <div class="apply-job-package bg-light-info rounded">
              <div>
                <h2 class="d-inline mr-25">
                  {{ stowage.product_qty }}
                </h2>
                <sub class="text-body"><small> seriales</small></sub>
              </div>
            </div>
            <b-button
              block
              variant="info"
              class="mt-2"
              @click="$router.push({name: 'stowage-detail', params: { stowage_id: stowage.stowage.id } })"
            >
              Ver estiba
            </b-button>

          </b-card-body>
        </b-card>

      </b-col> -->

      
      
    </b-row>

  </b-card>
  
</template>

<script>
import router from '@/router'
import { 
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BCardText,
  BBadge,
  BMediaAside,
  BMediaBody,
  BCardBody,
 } from 'bootstrap-vue'
import {constants} from "@/helpers/constants"
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import {formatDate} from "@/helpers/utils"

export default {
  components: {
    
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BCardText,
    BBadge,
    BMediaAside,
    BMediaBody,
    BCardBody,
  },
  data() {
    return {
      host: constants.hostUrl,
      accessToken: localStorage.getItem('accessToken'),
      orderId: null,
      order: null,
      stowages: null,
    }
  },
  created() {
    this.orderId = router.currentRoute.params.order_id
    console.log(this.orderId)
    this.getStowage()
    this.getOrder()
  },
  computed: {
    formatDateVariant() {
      return date => formatDate(date)
    },
  },
  methods: {
    getStowage() {
      let headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.accessToken
      }
      axios.get(this.host + '/api/front-web/get-order-stowages/' + this.orderId, { headers }).then(response => {
        console.log('get-order-stowages: ', response)

        this.stowages = response.data.data.stowages
        
      }).catch( err => {
        console.log(err)
      })
    },
    getOrder() {
      let headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.accessToken
      }
      axios.get(this.host + '/api/front-web/get-order-enlistment/' + this.orderId, { headers }).then(response => {
        console.log('get-order-enlistment: ', response)

        this.order = response.data
        
      }).catch( err => {
        console.log(err)
      })
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>