
export const constants = {
  hostUrl: 'https://backend.stoclistgrupo.com',
  ////https://backend.grupologisticopetro.com.co,
  //hostUrl: 'http://localhost:8000',

  endPoints: {
    settings: '/settings'
  },

  countries: [
    { label: 'Colombia', value: 1 },
  ],

  serializeds: [
    { label: 'Texto', value: 0 },
    { label: 'JSON', value: 1 },
  ]
}    